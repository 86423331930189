import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const CountryCards = () =>
  import(
    /* webpackChunkName: "country-cards" */ "../components/CountryCards.vue"
  );
const CountryDetails = () =>
  import(
    /* webpackChunkName: "country-details" */ "../components/CountryDetails.vue"
  );
const AdsComponent = () =>
  import(
    /* webpackChunkName: "ads-component" */ "../components/AdsComponent.vue"
  );
const AboutComponent = () =>
  import(
    /* webpackChunkName: "about-component" */ "../components/AboutComponent.vue"
  );
const SiteMapComponent = () =>
  import(
    /* webpackChunkName: "sitemap-component" */ "../components/SiteMapComponent.vue"
  );
const RobotComponent = () =>
  import(
    /* webpackChunkName: "robot-component" */ "../components/RobotComponent.vue"
  );
const PrivacyComponent = () =>
  import(
    /* webpackChunkName: "privacy-component" */ "../components/PrivacyComponent.vue"
  );
const BlogsComponent = () =>
  import(
    /* webpackChunkName: "blogs-component" */ "../components/BlogsComponent.vue"
  );
const BlogDetailsComponent = () =>
  import(
    /* webpackChunkName: "blog-details-component" */ "../components/view/BlogDetailsComponent.vue"
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:region?",
    name: "home",
    component: CountryCards,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/details/:countryName",
    name: "details",
    component: CountryDetails,
    meta: {
      title: "Country Details",
    },
  },
  {
    path: "/ads",
    name: "ads",
    component: AdsComponent,
    meta: {
      title: "Ads",
    },
  },
  {
    path: "/about",
    name: "about",
    component: AboutComponent,
    meta: {
      title: "About",
    },
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: SiteMapComponent,
    meta: {
      title: "Site Map",
    },
  },
  {
    path: "/sitemap.xml",
    name: "sitemap",
    component: SiteMapComponent,
    meta: {
      title: "Site Map",
    },
  },
  {
    path: "/ads.txt",
    name: "ads",
    component: AdsComponent,
    meta: {
      title: "Ads",
    },
  },
  {
    path: "/blogs",
    name: "blogs",
    component: BlogsComponent,
    meta: {
      title: "Blogs",
    },
  },
  {
    path: "/blogs/:id",
    name: "blogDetails",
    component: BlogDetailsComponent,
    meta: {
      title: "Blog Details",
    },
  },
  {
    path: "/robots.txt",
    name: "robots",
    component: RobotComponent,
    meta: {
      title: "Robots",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: PrivacyComponent,
    meta: {
      title: "Privacy",
    },
  },
  {
    path: "/:pathMatch(.*)*", // Catch-all route
    redirect: { name: "home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
