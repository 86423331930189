<template>
  <v-app>
    <v-main class="main-card">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>
<style>
.main-card {
  background-color: #efefef !important;
  font-family: "Nunito";
  font-display: swap;
}
</style>
